import React, { useEffect } from "react";

import { Fancybox } from "@fancyapps/ui";

import "@fancyapps/ui/dist/fancybox/fancybox.css";

const DiscoverVideo = ({ videoURL, videoThumb, options = {} }) => {
  // Initialize Fancybox for videos
  useEffect(() => {
    Fancybox.bind("[data-fancybox='video']", options);
    return () => {
      Fancybox.destroy(); // Clean up when the component unmounts
    };
  }, [options]);

  return (
    <div>
      <a href={videoURL} data-fancybox="video">
        <img src={videoThumb} alt={videoURL} />
        </a>
    </div>
  );
};

export default DiscoverVideo;
import React, { Component } from 'react';

// Images
import ImgPlayVideo from '../../assets/img/discover2/img-watch-video.png'
import ImgPlayVideoMobile from '../../assets/img/discover2/img-watch-video-mobile.png'
import ImgOvatoWallet from '../../assets/img/discover/img-ovato-wallet.png'
import ImgGovato from '../../assets/img/discover/img-govato.png'
import ImgSeries from '../../assets/img/discover2/img-series.png'
import ImgSeriesMobile from '../../assets/img/discover2/img-series-mobile.png'
import ImgSmall from '../../assets/img/discover2/img-small.png'
import ImgMedium from '../../assets/img/discover2/img-medium.png'
import ImgLarge from '../../assets/img/discover2/img-large.png'

import ImgSmallMobile from '../../assets/img/discover2/img-small-mobile.png'
import ImgMediumMobile from '../../assets/img/discover2/img-medium-mobile.png'
import ImgLargeMobile from '../../assets/img/discover2/img-large-mobile.png'

import { OVATO_URL } from '../../constants/defaultValues';
import ModalVideoManager from '../modal/ModalVideoManager';
import DiscoverVideo from '../video/DiscoverVideo';

class Section1 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <>
        <section class="home-1 new-home-2 new-home-2-new discover-1 v2-discover-1">
          <div class="container">
            <div class="row flex align-center home-1-row-2">
              <div class="col-sm-7 home-1-right-col home-1-right-col-new home-1-right-col-new-2">

                <div className='v2-mb-0 img-wrap discover-logo-img-wrap'>
                    <a href='/'><img src={ImgGovato} width={95} /></a>
                </div>

                <h1 class="poppins text-center v2-font-39 v2-m-font-22 bold white-text v2-m-mb-0"><strong class="double-underline double-underline-heading">Discover</strong></h1>
                
                <ul class="ul-checklist white-text discover-checklist">
                    <li>Great compensation package! </li>
                    <li>Tools and support! </li>
                    <li>Assets that are appreciable! </li>
                    <li>Royalties forever! </li>
                    <li>Multiple streams of income!</li>
                </ul>

                <div className='text-center img-wrap desktop discover-play-img'>
                    {/* <a href='https://www.youtube.com/watch?v=cekUUvu8JIM' target="_blank"><img src={ImgPlayVideo} /></a> */}
                    <DiscoverVideo videoURL={'https://www.youtube.com/watch?v=cekUUvu8JIM'} videoThumb={ImgPlayVideo} />
                </div>

                <div className='text-center img-wrap mobile discover-play-img'>
                    {/* <a href='https://www.youtube.com/watch?v=cekUUvu8JIM' target="_blank"><img src={ImgPlayVideo} /></a> */}
                    <DiscoverVideo videoURL={'https://www.youtube.com/watch?v=cekUUvu8JIM'} videoThumb={ImgPlayVideoMobile} />
                </div>
                
              </div>
              
              <div class="col-sm-5 relative">
                <div class="img-wrap new-banner-img">
                  <a href={'#'}><img src={ImgOvatoWallet} /></a>
                </div>
              </div>
              
            </div>
          </div>
          {/* <!-- end class container --> */}
        </section>

        <section className='discover-2 v2-discover-2 v2-tp-pt-2'>
            <div className='container'>
                <div className='row'>
                    <div className='col desktop text-center'>
                        <img src={ImgSeries} />
                    </div>

                    <div className='col mobile text-center'>
                        <img src={ImgSeriesMobile} />
                    </div>
                </div>
                <div className='row justify-content-center v2-discover-2-row-2'>
                    <div className='col col-sm-3'>
                        <img src={ImgSmall} className='desktop' />
                        <img src={ImgSmallMobile} className='mobile' />
                    </div>
                    <div className='col col-sm-4 v2-tp-mt-2 v2-m-mt-1'>
                        <img src={ImgMedium} className='desktop' />
                        <img src={ImgMediumMobile} className='mobile' />
                    </div>
                    <div className='col col-sm-4 v2-tp-mt-2 v2-m-mt-1'>
                        <img src={ImgLarge} className='desktop' />
                        <img src={ImgLargeMobile} className='mobile' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <div class="btn-wrap home-3-btn-wrap">
                            <a href='https://ovato.com/en/nfts' target='_blank' class="site-btn site-btn-2 site-btn-subtext  signup-v3-btn">
                                Join Now
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
  }
}

export default Section1;